<template>
  <transition name="shareShow">
    <div class="comment" :style="[{position:absolute ?'absolute' :null}]" @click="$emit('shareClose')">
      <div class="content" @click.stop>
        <div class="title flex">
          <div class="top-img"></div>
          <div class="title-w">{{topicInfo.content}}</div>
        </div>
        <div class="cover-box">
          <ImgDecypt class="cover-img" :src="topicInfo.videoCover||(topicInfo.repostMediaInfo&&topicInfo.repostMediaInfo.videoCover)||(topicInfo.picList && topicInfo.picList[0]) || topicInfo.author.cover" />
          <div class="btn"></div>
        </div>
        <div class="xian"></div>
        <div class="erweima flex">
          <div class="left" ref="qrCodeUrl"></div>
          <div class="right">
            <div class="title-ma">扫一扫下载色中色APP</div>
            <div class="url">{{uInfo.inviteUrl}}</div>
          </div>
        </div>
        <div class="c-btn flex-between">
          <div class="c-left" @click="copyImg">请手动截屏</div>
          <div class="c-right" @click="copyUrl">复制链接</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import QRCode from "qrcodejs2";
import { shareMedia } from "@/api/app.js";
export default {
  props: {
    topicInfo: {
      //话题博主信息
      type: Object,
      default: () => {
        return {};
      },
    },
    shareShow: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uInfo: JSON.parse(sessionStorage.getItem("userInfo")),
    };
  },
  created() {
    this.generateCode();
  },
  methods: {
    copyImg() {
      //手动分享截屏按钮
      this.shareMedia();
    },
    async shareMedia() {
      //分享
      let req = {
        mediaId: this.topicInfo.id,
      };
      this.$emit("shareSuccess");
      this.$emit("shareClose");
      await this.$Api(shareMedia, req);
    },
    // 生成二维码
    generateCode() {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.uInfo.inviteUrl,
          width: 119,
          height: 119,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    copyUrl() {
      //点击复制链接
      this.$copyText(this.uInfo.inviteUrl).then(
        () => {
          this.$toast("复制链接成功，快去分享吧！");
          this.shareMedia();
        },
        () => {
          this.$toast("复制链接失败");
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.comment {
  position: fixed;
  z-index: 6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
}
.content {
  position: absolute;
  bottom: 0;
  height: 488px;
  padding: 10px 15px;
  width: 328px;
  left: 50%;
  margin-left: -164px;
  top: 50%;
  margin-top: -244px;
  background: url("../../assets/png/share_bg.png") center center no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}
.title {
  align-items: center;
}
.top-img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 10px;
  background: url("../../assets/png/header.png") center center no-repeat;
  background-size: 100%;
}
.title-w {
  color: #ffffff;
  font-size: 16px;
  flex: 1;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cover-box {
  position: relative;
}
.cover-img {
  margin-top: 8px;
  width: 100%;
  height: 171px;
  border-radius: 4px;
  overflow: hidden;
}
.btn {
  position: absolute;
  width: 44px;
  height: 44px;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  background: url("../../assets/png/share_play.png") center center no-repeat;
  background-size: 100%;
}
.xian {
  margin: 15px 0;
  border-top: 1px dashed #ffffff;
  width: 100%;
}
.erweima {
  width: 100%;
  align-items: center;
}
.left {
  width: 134px;
  height: 134px;
  background: rgba(255, 255, 255, 0.4);
  padding: 7px;
  box-sizing: border-box;
  margin-right: 14px;
  flex-shrink: 0;
}
.right {
  font-size: 16px;
  color: #ffffff;
}
.url {
  margin-top: 15px;
  word-break: break-all;
}
.c-btn {
  margin-top: 28px;
}
.c-btn > div {
  width: 136px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  border-radius: 9px;
  font-size: 18px;
}
.c-left {
  background: #efefef;
}
.c-right {
  background: $vermillion;
  color: #ffffff;
}
</style>