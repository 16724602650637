<template>
  <transition name="shareShow">
    <div class="comment" :style="[{position:absolute ?'absolute' :null}]" @click="$emit('commentClose')">
      <transition name="commentShow">
        <div class="content" @click.stop v-show="commentShow">
          <div class="top">
            <div class="title">
              <div class="heng"></div>
              <div class="title-c">评论（{{topicInfo.commentNum}}）</div>
            </div>
            <div class="comment-list">
              <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="commentList.length>0">
                <div class="comment-item" v-for="(item,index) in commentList" :key="item.detail.id">
                  <div class="item flex-between">
                    <div class="item-left flex">
                      <ImgDecypt :round="true" class="item-left-img" :src="item.detail.authorAvtar" />
                      <div class="name" @click="commentSon(item.detail.id,index)">
                        <p class="user-n">{{item.detail.authorNickName}}</p>
                        <p class="user-w">{{item.detail.comment}}</p>
                      </div>
                    </div>
                    <div class="item-right">{{item.detail&&item.detail.createAt|dateago}}</div>
                  </div>
                  <div class="more-reply" v-if="item.replyCount>0" :aa="item.smallComment">
                    <div class="open" :aa="Boolean(item.smallComment)"><span @click="openComment(item,index)">{{item.smallComment?'收起':'展开'}}回复<span class="open-icon"></span></span></div>
                    <div class="message-more" v-show="item.smallComment">
                      <div class="small-message flex" v-for="items in item.sonCommentList" :key="items.detail.id">
                        <ImgDecypt :round="true" class="small-h" :src="item.detail.authorAvtar" />
                        <div class="small-w" @click="commentSon(item.detail.id,index)">
                          <div class="small-name">{{items.detail.authorNickName}} 回复：{{item.detail.authorNickName}}</div>
                          <div class="small-words">{{items.detail.comment}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </pullrefresh>
              <van-loading type="spinner" color="#f21313" v-show="commentList&&commentList.length==0 && !noData" />
              <nodata description="暂无评论，快来添加吧！" v-show="commentList.length==0&&noData" />
            </div>
          </div>
          <div class="btn flex-between">
            <input class="btn-input" @blur="inputBlur" ref="input" v-model="commentWords" placeholder="留下你精彩的评论吧" type="text">
            <div class="btn-send" @click="commentPublist"></div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import { getComment, commentPublist, sonComment } from "@/api/app.js";
import pullrefresh from "@/components/PullRefresh/index.vue";
export default {
  components: {
    pullrefresh,
  },
  props: {
    commentShow: {
      type: Boolean,
      default: false,
    },
    topicInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageNum: 1, //页码
      commentList: [], //评论列表
      noData: false, //暂无数据
      commentWords: "", //评论语
      sonCommentId: "", //父评论的id
      index: "", //点击的是主评论的index
    };
  },
  methods: {
    inputBlur() {
      //失去焦点
      setTimeout(() => {
        this.sonCommentId = "";
      }, 500);
    },
    commentSon(id, index) {
      //点击父评论 对父评论进行评论
      this.index = index;
      this.sonCommentId = id;
      this.$refs.input.focus();
    },
    openComment(item, index) {
      //展开子评论
      let obj = this.commentList[index];
      obj.smallComment = !this.commentList[index].smallComment;
      this.$set(this.commentList, index, obj);
      if (obj.smallComment && !obj.sonCommentList) {
        //点击展示的时候并且在sonCommentList为空的时候才请求
        this.sonComment(item.detail.id, index);
      }
    },
    async sonComment(id, index) {
      //获取主评论下的子评论
      let req = {
        mediaId: this.topicInfo.id,
        page: 1,
        pageSize: 50,
        parentId: id,
      };
      let ret = await this.$Api(sonComment, req);
      if (ret.code == 200) {
        let list = ret.data.comments ? ret.data.comments : [];
        let obj = this.commentList[index];
        obj.sonCommentList = list;
        this.$set(this.commentList, index, obj);
      }
    },
    async commentPublist() {
      //发布评论
      if (this.commentWords.trim()) {
        let sonCommentId = this.sonCommentId; //父评论id 如果有值就说明是二级评论
        let req = {
          comment: this.commentWords,
          mediaId: this.topicInfo.id,
          parentId: this.sonCommentId || 0,
          replyId: this.sonCommentId || 0,
        };
        let ret = await this.$Api(commentPublist, req);
        this.commentWords = "";
        if (ret.code == 200) {
          this.$toast("评论成功");
          if (!sonCommentId) {
            //主评论成功
            let obj = {
              detail: ret.data.detail,
              replyCount: 0,
            };
            this.$emit("commentSuccess");
            this.commentList.unshift(obj);
          } else {
            //子评论成功
            if (this.commentList[this.index].replyCount == 0) {
              //如果该评论下没有子评论
              let obj = this.commentList[this.index];
              obj.replyCount++;
              obj.smallComment = true;
              obj.sonCommentList = [];
              obj.sonCommentList.push(ret.data);
              this.$set(this.commentList, this.index, obj);
            } else {
              ////如果该评论下有子评论
              let obj = this.commentList[this.index];
              obj.sonCommentList.unshift(ret.data);
              this.$set(this.commentList, this.index, obj);
            }
          }
        } else {
          if (ret.tip) {
            this.$toast(ret.tip);
          } else {
            this.$toast("暂时无法评论，请稍后再试！");
          }
        }
      } else {
        this.$toast("请填写你的评论，再提交评论吧");
      }
    },
    async getComment() {
      //获取评论列表
      let req = {
        mediaID: this.topicInfo.id,
        page: this.pageNum,
        pageSize: 20,
      };
      let ret = await this.$Api(getComment, req);
      if (!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if (ret.code == 200) {
        if (this.pageNum == 1) {
          this.commentList = [];
        }
        let list = ret.data.comments ? ret.data.comments : [];
        this.commentList = this.commentList.concat(list);
        if (list.length < 20) {
          this.$refs.pull.finished = true;
        } else {
          this.$refs.pull.finished = false;
        }
        if (this.pageNum == 1 && list.length == 0) {
          this.noData = true;
        }
      }
    },
    onLoad() {
      this.pageNum++;
      this.getComment();
    },
  },
  watch: {
    commentShow(newval) {
      if (newval) {
        this.commentList = [];
        this.noData = false;
        this.pageNum = 1;
        this.getComment();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.comment {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
}
.content {
  position: absolute;
  bottom: 0;
  height: 450px;
  padding: 0 16px;
  width: 100%;
  background: #ffffff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-sizing: border-box;
}
.title {
  text-align: center;
  padding: 2px 0 10px;
}
.heng {
  display: inline-block;
  width: 139px;
  height: 6px;
  border-radius: 6px;
  background: #e0e5eb;
}
.title-c {
  margin-top: 5px;
  font-size: 15px;
}
.comment-list {
  height: 360px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.btn {
  position: absolute;
  bottom: 0;
  height: 43px;
  align-items: center;
  left: 16px;
  right: 16px;
}
.btn-input {
  flex: 1;
  height: 34px;
  border-radius: 19px;
  background: #e0e5eb;
  text-indent: 16px;
}
.btn-send {
  margin-left: 7px;
  width: 34px;
  height: 34px;
  background: url("../../assets/png/btn_send.png") center center no-repeat;
  background-size: 100%;
}
.item {
  margin-top: 8px;
}
.item-left {
  flex: 1;
}
.item-left > div {
  flex-shrink: 0;
}
.name {
  flex: 1;
}
.item-left-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #f21313;
  margin-right: 9px;
}
.item-right {
  color: #b9b9b9;
}
.user-n {
  color: #515151;
  margin-top: 4px;
  font-size: 14px;
}
.user-w {
  margin-top: 5px;
}
.comment-list /deep/ .van-list__finished-text {
  display: none;
}
.more-reply {
  text-align: right;
  font-size: 10px;
  margin-bottom: 18px;
}
.open-icon {
  display: inline-block;
  width: 10px;
  height: 6px;
  vertical-align: 1px;
  margin-left: 6px;
  background: url("../../assets/png/down.png") center center no-repeat;
  background-size: 100%;
}
.message-more {
  padding: 0 40px;
  font-size: 10px;
  text-align: left;
}
.small-message {
  margin-top: 10px;
}
.small-h {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #f21313;
  margin-right: 6px;
}
.small-name {
  color: #b9b9b9;
}
.small-w {
  flex: 1;
}
.small-words {
  margin-top: 6px;
}
</style>